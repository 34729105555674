import { graphql, HeadFC } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../../components/layout/Layout';
import Link from '../../components/Link';
import { useLocation } from '@reach/router';

type Props = {
  data: any;
};

const pathNames: any = {
  '/privacy-policy-draft/': 'Privacy Policy Draft',
  '/return-policy/': 'Return Policy',
  '/terms-conditions/': 'Terms and Conditions',
  '/privacy-policy/': 'Privacy Policy',
  '/homepage/': 'Homepage',
  '/dev-404-page/': '404 Page',
  '/404/': '404 Not Found',
  '/404.html': '404 Not Found',
  '/': 'Home',
  '/place-order/': 'Place Order',
  '/test/': 'Test Page',
  '/thankyou/': 'Thank You',
  '/about/': 'About Us',
  '/cart/': 'Shopping Cart',
  '/all-peptides/': 'All Peptides',
  '/checkout/': 'Checkout',
  '/category/': 'Category',
  '/forgot-password/': 'Forgot Password',
  '/login/': 'Login',
  '/contact-us/': 'Contact Us',
  '/my-account/': 'My Account',
  '/my-addresses/': 'My Addresses',
  '/register/': 'Register',
  '/reset-password/': 'Reset Password',
  '/search/': 'Search',
  '/my-orders/': 'My Orders',
  '/blog/': 'All blogs',
  '/sitemap/': 'Sitemap',
};

const matchedPatterns = [
  /^\/blog(\/.*)?$/,
  /^\/products(\/.*)?$/,
  /^\/blog-category(\/.*)?$/,
  /^\/reset-password(\/.*)?$/,
  /^\/product-category(\/.*)?$/,
  /^\/404.html(\/.*)?$/,
  /^\/dev-404-page(\/.*)?$/,
  /^\/place-order(\/.*)?$/,
  /^\/thankyou(\/.*)?$/,
  /^\/privacy-policy-draft(\/.*)?$/,
  /^\/search(\/.*)?$/,
  /^\/sitemap(\/.*)?$/,
  /^\/homepage(\/.*)?$/,
  /^\/offline-plugin-app-shell-fallback(\/.*)?$/,
];

interface IPropsSectionList {
  title: string;
  items: any[];
}

const SectionList: FC<IPropsSectionList> = ({ title, items }) => (
  <div className="flex flex-col items-start w-full site-list">
    <h2>{title}</h2>
    <ul>
      {items.map(({ node }) => {
        const displayName = pathNames[node.path] || node.path;
        return (
          <li key={node.path}>
            <Link to={node.path}>
              {(
                node.pageContext?.name ||
                node.pageContext?.title ||
                displayName
              ).replace('&amp;', '&')}
            </Link>
          </li>
        );
      })}
    </ul>
  </div>
);

const Sitemap: FC<Props> = ({ data }) => {
  const { edges: blogs } = data.blogs;
  const { edges: products } = data.products;
  const { edges: blogCategories } = data.blogCategories;
  const { edges: productCategories } = data.productCategories;
  const { edges: allPaths } = data.allPages;

  // const unmatchedPaths = allPaths.filter(
  // 	(path: any) =>
  // 		!matchedPatterns.some((pattern) => pattern.test(path.node.path))
  // );

  return (
    <Layout>
      <section className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain pt-[10px] pb-[40px]">
        <h1 className="mt-4 border-b w-full font-sofiaBold font-medium md:text-[40px] text-[25px] md:pb-3 pb-2 mb-5">
          Spartan Peptides Site Map
        </h1>
        <div className=" sitemap-sec">
          <SectionList title="Blogs" items={blogs} />
          <SectionList title="Products" items={products} />
          <SectionList title="Blog Categories" items={blogCategories} />
          <SectionList title="Product Categories" items={productCategories} />
          <SectionList title="All Pages" items={allPaths} />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    blogs: allSitePage(
      filter: { path: { regex: "/^/blog(/.*)?$/" } }
      sort: { path: ASC }
    ) {
      edges {
        node {
          path
          pageContext
        }
      }
    }

    products: allSitePage(
      filter: { path: { regex: "/^/products(/.*)?$/" } }
      sort: { path: ASC }
    ) {
      edges {
        node {
          path
          pageContext
        }
      }
    }

    blogCategories: allSitePage(
      filter: { path: { regex: "/^/blog-category(/.*)?$/" } }
      sort: { path: ASC }
    ) {
      edges {
        node {
          path
          pageContext
        }
      }
    }

    productCategories: allSitePage(
      filter: {
        path: {
          regex: "/^/product-category(?!/uncategorized|/discounted-upsell-products|/dummy-products)(/.*)?$/"
        }
      }
      sort: { path: ASC }
    ) {
      edges {
        node {
          path
          pageContext
        }
      }
    }

    allPages: allSitePage(
      filter: {
        path: { in: ["/", "/about/", "/all-peptides/", "/contact-us/"] }
      }
      sort: { path: ASC }
    ) {
      edges {
        node {
          path
          pageContext
        }
      }
    }
  }
`;

export default Sitemap;

export const Head = (props: any) => {
  const location = useLocation();

  return (
    <>
      <title>Sitemap</title>
      <link
        rel="canonical"
        href={`${process.env.GATSBY_CLIENT_URI}${location.pathname}`}
      ></link>
      <meta name="robots" content={'index, follow'} />
    </>
  );
};
